<template>
  <div
    class="
      toolbar
      d-flex
      flex-row
      align-items-center
      justify-content-center
      py-1
    "
    :class="{ disabled: lock }"
  >
    <!-- Heading -->
    <div class="selected-heading">
      <b-dropdown
        id="heading-dropdown"
        size="lg"
        variant="link"
        toggle-class="text-decoration-none heading-dropdown"
        no-caret
      >
        <template #button-content>
          <i :class="selectedHeading.icon" />
          <span class="sr-only">{{ selectedHeading.label }}</span>
        </template>
        <b-dropdown-item
          v-for="(heading, idx) in headingsList"
          :key="idx"
          @click="selectHeading(heading)"
        >
          {{ heading.label }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="v-divider ml-50 mr-50" />
    <!-- Font -->
    <b-form-select
      class="font-select pl-0"
      :value="selectedFont"
      @change="selectFont"
    >
      <b-form-select-option value="Open Sans">
        Open Sans
      </b-form-select-option>
      <b-form-select-option
        v-for="(font, index) of fontsList"
        :key="`font-${index}`"
        :value="font"
      >
        {{ font }}
      </b-form-select-option>
    </b-form-select>
    <!-- Font Size -->
    <b-form-select
      class="format-select pl-0"
      :value="
        editor.getAttributes('textStyle').fontSize
          ? editor.getAttributes('textStyle').fontSize
          : selectedFontSize
      "
      @change="editor.chain().setFontSize($event).run()"
    >
      <b-form-select-option
        v-for="(fontSize, index) of fontSizesList"
        :key="`font-${index}`"
        :value="fontSize"
      >
        {{ fontSize }}
      </b-form-select-option>
    </b-form-select>
    <div class="v-divider mr-1" />
    <!-- Color -->
    <input
      type="color"
      :value="editor.getAttributes('textStyle').color"
      @input="editor.chain().focus().setColor($event.target.value).run()"
    >
    <div class="v-divider ml-1 mr-50" />
    <!-- Bold -->
    <button
      class="toolbar-btn"
      :class="{ active: editor.isActive('bold') }"
      @click="editor.chain().focus().toggleBold().run()"
    >
      <i class="ri-bold ri-lg" />
    </button>
    <!-- Italic -->
    <button
      class="toolbar-btn"
      :class="{ active: editor.isActive('italic') }"
      @click="editor.chain().focus().toggleItalic().run()"
    >
      <i class="ri-italic ri-lg" />
    </button>
    <!-- Underline -->
    <button
      class="toolbar-btn"
      :class="{
        active: editor.isActive('underline'),
      }"
      @click="editor.chain().focus().toggleUnderline().run()"
    >
      <i class="ri-underline ri-lg" />
    </button>
    <div class="v-divider ml-50 mr-50" />
    <!-- Align left -->
    <button
      class="toolbar-btn"
      :class="{ active: editor.isActive({ textAlign: 'left' }) }"
      @click="editor.chain().focus().setTextAlign('left').run()"
    >
      <i class="ri-align-left ri-lg" />
    </button>
    <!-- Align center -->
    <button
      class="toolbar-btn"
      :class="{ active: editor.isActive({ textAlign: 'center' }) }"
      @click="editor.chain().focus().setTextAlign('center').run()"
    >
      <i class="ri-align-center ri-lg" />
    </button>
    <!-- Align right -->
    <button
      class="toolbar-btn"
      :class="{ active: editor.isActive({ textAlign: 'right' }) }"
      @click="editor.chain().focus().setTextAlign('right').run()"
    >
      <i class="ri-align-right ri-lg" />
    </button>
    <!-- Align justify -->
    <button
      class="toolbar-btn"
      :class="{ active: editor.isActive({ textAlign: 'justify' }) }"
      @click="editor.chain().focus().setTextAlign('justify').run()"
    >
      <i class="ri-align-justify ri-lg" />
    </button>
    <div class="v-divider ml-50 mr-50" />
    <!-- Font Line Height -->
    <!-- Heading -->
    <div class="selected-heading">
      <b-dropdown
        id="heading-dropdown"
        size="lg"
        variant="link"
        toggle-class="text-decoration-none heading-dropdown"
        no-caret
      >
        <template #button-content>
          <i class="ri-line-height" />
          <span class="sr-only">Interligne</span>
        </template>
        <b-dropdown-item
          v-for="(lineHeight, idx) in lineHeightsList"
          :key="idx"
          @click="editor.chain().setLineHeight(lineHeight).run()"
        >
          {{ lineHeight }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <!-- Bullet list -->
    <button
      class="toolbar-btn"
      :class="{ active: editor.isActive('bulletList') }"
      @click="editor.chain().focus().toggleBulletList().run()"
    >
      <i class="ri-list-unordered ri-lg" />
    </button>
    <!-- Ordered list -->
    <button
      class="toolbar-btn"
      :class="{ active: editor.isActive('orderedList') }"
      @click="editor.chain().focus().toggleOrderedList().run()"
    >
      <i class="ri-list-ordered ri-lg" />
    </button>
    <div class="v-divider ml-1 mr-50" />
    <!-- Image -->
    <button
      class="toolbar-btn"
      @click="addImage"
    >
      <i class="ri-image-2-line ri-lg" />
    </button>
    <div class="v-divider ml-50 mr-50" />
    <!-- Table -->
    <button
      class="toolbar-btn"
      @click="
        editor
          .chain()
          .focus()
          .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
          .run()
      "
    >
      <i class="ri-table-2 ri-lg" />
    </button>
    <div class="v-divider ml-50 mr-50" />
    <!-- More -->
    <div class="selected-heading">
      <b-dropdown
        size="lg"
        variant="link"
        toggle-class="text-decoration-none more-dropdown"
        no-caret
      >
        <template #button-content>
          <i class="ri-add-line ri-lg" />
        </template>
        <b-dropdown-item
          v-if="hasHeader"
          @click="removeHeader()"
        >
          Retirer l'en-tête
        </b-dropdown-item>
        <b-dropdown-item
          v-else
          @click="addHeader()"
        >
          Ajouter l'en-tête
        </b-dropdown-item>
        <b-dropdown-item
          v-if="hasFooter"
          @click="removeFooter()"
        >
          Retirer le pied de page
        </b-dropdown-item>
        <b-dropdown-item
          v-else
          @click="addFooter()"
        >
          Ajouter le pied de page
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <!-- <div class="v-divider mx-1" /> -->
    <!-- More -->
    <!-- <button class="toolbar-btn"><i class="ri-add-box-line ri-lg" /></button> -->
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  props: {
    editor: {
      required: true,
    },
  },
  data() {
    return {
      selectedFormat: "paragraph",
      selectedFont: "Open Sans", // Défaut
      fontsList: [
        "Arial",
        "Verdana",
        "Times New Roman",
        "Courrier New",
        "Garamond",
        "Century Gothic",
        "Signatie",
        "Basic Sans",
        "Necto Mono",
      ],
      selectedFontSize: 12,
      fontSizesList: [8, 10, 12, 14, 16, 20, 24, 32, 40, 48, 56, 72, 96],
      selectedLineHeight: 1.0,
      lineHeightsList: [1.0, 1.5, 1.8, 2.0, 3.0],
      selectedHeading: { icon: "ri-text", label: "Paragraphe" },
      headingsList: [
        { icon: "ri-text", label: "Paragraphe" },
        { icon: "ri-h-1", label: "Titre 1" },
        { icon: "ri-h-2", label: "Titre 2" },
        { icon: "ri-h-3", label: "Titre 3" },
      ],
    };
  },
  computed: {
    ...mapState("editor", ["hasHeader", "hasFooter", "lock"]),
  },
  watch: {
    selectedHeading: function (value) {
      if (value.label === "Titre 1") {
        this.editor.chain().focus().setHeading({ level: 1 }).run();
      } else if (value.label === "Titre 2") {
        this.editor.chain().focus().setHeading({ level: 2 }).run();
      } else if (value.label === "Titre 3") {
        this.editor.chain().focus().setHeading({ level: 3 }).run();
      } else if (value.label === "Paragraphe") {
        this.editor.chain().focus().setParagraph().run();
      }
    },
  },
  mounted() {
    this.editor.on("selectionUpdate", ({ editor }) => {
      if (editor.isActive("heading", { level: 1 })) {
        this.selectedHeading = this.headingsList[1];
      } else if (editor.isActive("heading", { level: 2 })) {
        this.selectedHeading = this.headingsList[2];
      } else if (editor.isActive("heading", { level: 3 })) {
        this.selectedHeading = this.headingsList[3];
      } else if (editor.isActive("paragraph")) {
        this.selectedHeading = this.headingsList[0];
      }

      this.selectedFont = "Open Sans";
      for (const font of this.fontsList) {
        if (this.editor.isActive("textStyle", { fontFamily: font })) {
          this.selectedFont = font;
        }
      }
    });
  },
  methods: {
    ...mapMutations("editor", ["SET_HAS_HEADER", "SET_HAS_FOOTER"]),
    selectFont(value) {
      if (value === "Open Sans") {
        this.editor.chain().focus().unsetFontFamily().run();
      } else {
        this.editor.chain().focus().setFontFamily(value).run();
      }
    },
    selectHeading(newHeading) {
      this.selectedHeading = newHeading;
    },
    addHeader() {
      this.editor.commands.insertContentAt(0, "<header><p>Contenu de l'en-tête...</p></header>");
      this.SET_HAS_HEADER(true);
    },
    addFooter() {
      this.editor.commands.insertContentAt(this.editor.getCharacterCount(), '<footer><p>Contenu du pied de page...</p></footer>')

      // Remove empty trailing paragraph (<p></p>)
      const json = this.editor.getJSON();
      const idx = json.content.length - 1;
      json.content.splice(idx, 1);
      this.editor.commands.setContent(json);

      this.SET_HAS_FOOTER(true);
    },
    removeNodeByTypeName(nodeTypeName) {
      for (const e of this.editor.view.docView.children) {
        console.log(e?.spec?.node?.type?.name);
      }

      const header = this.editor.view.docView.children.find(e => {
        return e && e.spec && e.spec.node && e.spec.node.type && e.spec.node.type.name === nodeTypeName
      });

      if (header) {
        header.spec.deleteNode();
      }
    },
    removeHeader() {
      this.removeNodeByTypeName("Header");
      this.SET_HAS_HEADER(false);
    },
    removeFooter() {
      this.removeNodeByTypeName("Footer");
      this.SET_HAS_FOOTER(false);
    },
    addImage() {
      const url = window.prompt(
        "Image URL",
        // Using picsum.photo/id/... and NOT
        // picsum.photos/w/h or the image will
        // change when we change the size and
        // tiptap redraws
        `https://picsum.photos/id/${
          Math.floor(Math.random() * 200) + 1
        }/200/300`
      );
      if (url) {
        this.editor.chain().focus().setImage({ src: url }).run();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.toolbar {
  border-bottom: 0.7px solid $gray-400;
}

.toolbar-btn {
  margin: 0 1px;
  padding: 8px 8px 3px 8px;
  background-color: transparent;
  border: none;
  border-radius: $border-radius;
  color: $black-400;

  &:hover {
    color: $black-500;
    background-color: $gray-300;
  }

  &.active {
    color: $vitalsign-blue;
    background-color: $blue-200;
  }
}

.format-select {
  width: auto;
  border: none;
  background-color: $gray-200;
  font-size: $font-size-base;

  &:focus {
    box-shadow: none;
  }
}

.font-select {
  max-width: 100px;
  border: none;
  background-color: $gray-200;
  font-size: $font-size-base;

  &:focus {
    box-shadow: none;
  }
}

.lineheight-select {
  margin-right: -4px;
}

.selected-heading {
  background-color: transparent !important;
  border-radius: $border-radius;
  padding: 0;
}

input[type="color"] {
  -webkit-appearance: none;
  border: none;
  width: 22px;
  height: 22px;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.4;
  
  * {
    pointer-events: none;
  }
}
</style>