<template>
  <div
    v-if="editor"
    class="bubble-menu"
    :editor="editor"
  >
    <button
      v-show="editor.can().addColumnBefore()"
      class="bubble-btn"
      @click="editor.chain().focus().addColumnBefore().run()"
    >
      <i class="ri-insert-column-left" />
    </button>
    <button
      v-show="editor.can().addColumnAfter()"
      class="bubble-btn"
      @click="editor.chain().focus().addColumnAfter().run()"
    >
      <i class="ri-insert-column-right" />
    </button>
    <button
      v-show="editor.can().deleteColumn()"
      class="bubble-btn"
      @click="editor.chain().focus().deleteColumn().run()"
    >
      <i class="ri-delete-column" />
    </button>
    <button
      v-show="editor.can().addRowBefore()"
      class="bubble-btn"
      @click="editor.chain().focus().addRowBefore().run()"
    >
      <i class="ri-insert-row-top" />
    </button>
    <button
      v-show="editor.can().addRowAfter()"
      class="bubble-btn"
      @click="editor.chain().focus().addRowAfter().run()"
    >
      <i class="ri-insert-row-bottom" />
    </button>
    <button
      v-show="editor.can().deleteRow()"
      class="bubble-btn"
      @click="editor.chain().focus().deleteRow().run()"
    >
      <i class="ri-delete-row" />
    </button>
    <button
      v-show="editor.can().mergeCells()"
      class="bubble-btn"
      @click="editor.chain().focus().mergeCells().run()"
    >
      <i class="ri-merge-cells-horizontal" />
    </button>
    <button
      v-show="editor.can().splitCell()"
      class="bubble-btn"
      @click="editor.chain().focus().splitCell().run()"
    >
      <i class="ri-split-cells-horizontal" />
    </button>
    <button
      v-show="editor.can().deleteTable()"
      class="bubble-btn"
      @click="editor.chain().focus().deleteTable().run()"
      >
      <i class="ri-delete-bin-7-fill" />
    </button>
    <button
      v-show="editor.can().setBorderless()"
      class="bubble-btn"
      @click="editor.chain().focus().setBorderless().run()"
    >
      <i class="ri-grid-fill" />
    </button>
    <button
      v-show="editor.can().unsetBorderless()"
      class="bubble-btn"
      @click="editor.chain().focus().unsetBorderless().run()"
    >
      <i class="ri-grid-line" />
    </button>
    <!-- <button
            @click="editor.chain().focus().toggleHeaderColumn().run()"
            v-show="editor.can().toggleHeaderColumn()"
        >
            En-tête
        </button>
        <button
            @click="editor.chain().focus().toggleHeaderRow().run()"
            v-show="editor.can().toggleHeaderRow()"
        >
            toggleHeaderRow
        </button>
        <button
            @click="editor.chain().focus().toggleHeaderCell().run()"
            v-show="editor.can().toggleHeaderCell()"
        >
            toggleHeaderCell
        </button> -->
    <!-- <button
            @click="editor.chain().focus().fixTables().run()"
            v-show="editor.can().fixTables()"
        >
            fixTables
        </button> -->
  </div>
</template>

<script>
export default {
  props: {
    editor: {
      required: true,
    },
  },
};
</script>