<template>
  <b-input-group class="search-bar">
    <template #prepend>
      <i class="ri-search-line ri-lg ml-1 my-auto" />
    </template>
    <b-form-input
      type="search"
      :placeholder="placeholderText"
      @input="onInput"
    />
  </b-input-group>
</template>

<script>
export default {
  props: {
    placeholderText: {
      type: String,
      required: false,
      default: "Rechercher",
    },
  },
  data() {
    return {
      searchQuery: "",
    };
  },
  methods: {
    onInput(e) {
      this.$emit('input', e);
    },
    resetQuery() {
      document.querySelector('input[type=search]').value = '';
    },
  }
};
</script>

<style lang="scss" scoped>
.search-bar {
  border: 1px solid $gray-400;
  border-radius: $border-radius;
  background-color: $gray-200;
  transition: border-color 0.15s ease-in-out;

  &:hover {
    border: 1px solid $gray-500;
  }

  &:focus,
  &:focus-within,
  &:active {
    border: 1px solid $gray-500;
    box-shadow: none !important;
  }

  i {
      padding-bottom: 1px;
  }

  input {
    border: none;
    font-size: $font-size-base;

    &:focus,
    &:focus:valid,
    &:focus.is-valid {
        border: none;
        box-shadow: none;
    }
  }
}
</style>