<template>
  <div class="wrapper d-flex flex-column flex-grow-1">
    <!-- 
          Barre d'actions du document (titre, sauvegarde) 
        -->
    <top-bar v-if="editor" :editor="editor" />

    <!-- 
          Section principale
        -->
    <div class="d-flex flex-row flex-grow-1 align-items-stretch">
      <!-- Éditeur -->
      <div class="editor d-flex flex-column flex-grow-1">
        <custom-bubble-menus :editor="editor" />
        <tool-bar v-if="editor" :editor="editor" />
        <composer v-if="editor" :editor="editor" :template="template" />
      </div>
      <!-- Sidebar champs dynamiques -->
      <side-bar
        @add-to-doc="addToDoc"
        @remove-from-doc="removeFromDoc"
        @add-module-to-doc="addModuleToDoc"
        @update-field="updateInDoc"
      />
    </div>
  </div>
</template>

<script>
import axios from "@axios";

import { Editor } from "@tiptap/vue-2";
import BubbleMenu from "@tiptap/extension-bubble-menu";
import { Color } from "@tiptap/extension-color";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Placeholder from "@tiptap/extension-placeholder";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import TextStyle from "@tiptap/extension-text-style";
import FontFamily from "@tiptap/extension-font-family";

// import Table from "@tiptap/extension-table";
import Table from "@/components/editor/plugins/table/CustomTableExtension.js";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";

import TopBar from "@/components/editor/TopBar";
import ToolBar from "@/components/editor/ToolBar";
import SideBar from "@/components/editor/SideBar";
import Composer from "@/components/editor/Composer";

import DynamicField from "@/components/editor/plugins/dynamic-field/DynamicFieldExtension.js";
import Checkboxes from "@/components/editor/plugins/checkboxes/CheckboxesExtension.js";
import Questionnaire from "@/components/editor/plugins/questionnaire/QuestionnaireExtension.js";
import CustomImage from "@/components/editor/plugins/custom-image/CustomImageExtension.js";
import Header from "@/components/editor/plugins/header/HeaderExtension.js";
import Footer from "@/components/editor/plugins/footer/FooterExtension.js";
import FontSize from "@/components/editor/plugins/FontSize.js";
import LineHeight from "@/components/editor/plugins/LineHeight.js";
import { mapActions, mapMutations, mapState } from "vuex";

import CustomBubbleMenus from "@/components/editor/plugins/bubble-menus/CustomBubbleMenus";

export default {
  components: {
    TopBar,
    ToolBar,
    SideBar,
    Composer,
    CustomBubbleMenus,
  },
  data() {
    return {
      editor: null,
      documentTemplate: {
        id: null,
      },
    };
  },
  computed: {
    ...mapState("editor", ["dynamicFields", "title", "type", "template"]),
  },
  mounted() {
    this.editor = new Editor({
      // [Test] Prevent content after Footer
      // onTransaction({ editor, transaction }) {
      //   const json = editor.getJSON();
      //   const pos = transaction.curSelection.from;
      //   const footerIdx = json.content.findIndex(e => e.type === "Footer");
      //   if (footerIdx && json.content.length > footerIdx + 1) {
      //     json.content.splice(footerIdx + 1, json.content.length - footerIdx + 1);
      //     editor.commands.setContent(json);
      //     editor.commands.setTextSelection(pos);
      //   }
      // },
      extensions: [
        StarterKit,
        Underline,
        TaskList,
        TaskItem,
        DynamicField,
        TextStyle,
        FontFamily,
        Color,
        CustomImage.configure({
          HTMLAttributes: {
            class: "custom-image",
          },
        }),
        Placeholder.configure({
          placeholder: "Composez votre document...",
        }),
        TextAlign.configure({
          types: ["heading", "paragraph"],
          defaultAlignment: "left",
        }),
        Table,
        TableRow,
        TableHeader,
        TableCell,
        Checkboxes,
        Questionnaire,
        FontSize,
        LineHeight,
        Header,
        Footer,
        BubbleMenu.configure({
          pluginKey: "bubbleMenuImage",
          element: document.getElementById("bubble-menu-image"),
          shouldShow: ({ editor, view, state, oldState, from, to }) => {
            // only show the bubble menu for images and links
            return editor.isActive("custom-image");
          },
        }),
        BubbleMenu.configure({
          pluginKey: "bubbleMenuTable",
          element: document.getElementById("bubble-menu-table"),
          shouldShow: ({ editor, view, state, oldState, from, to }) => {
            // only show the bubble menu for images and links
            return (
              editor.isActive("table") ||
              editor.isActive("table-row") ||
              editor.isActive("table-cell")
            );
          },
        }),
      ],
      content: "",
      editorProps: {
        attributes: {
          class: "mx-auto no-outline",
          spellcheck: "false",
        },
        transformPastedHTML: (html) => {
          try {
            console.log(html);
            html = html.replace(/<br ?\/?><p/g, "<p");
            html = html.replace(/<\/p><br ?\/?>/g, "</p>");

            const parser = new DOMParser();
            const doc = parser
              .parseFromString(html, "text/html")
              .getElementsByTagName("body")[0];

            doc
              .querySelectorAll("span, p, strong")
              .forEach(
                (el) =>
                  el.textContent.trim() === "" && el.parentNode.removeChild(el)
              );

            console.log(doc.innerHTML);
            return doc.innerHTML;
          } catch (err) {
            console.error("deep parsing error", err);
            return html;
          }
        },
      },
      autofocus: true,
      injectCSS: false,
    });

    axios
      .get(`/document-templates/${this.$route.params.id}`)
      .then((res) => {
        this.updateDocument(res.data);
        this.editor.commands.setContent(res.data.content);
      })
      .catch((err) => {
        alert(err.toString());
      });
  },
  beforeDestroy() {
    this.resetDocument();
    this.editor.destroy();
  },
  methods: {
    ...mapActions("editor", ["updateDocument", "resetDocument"]),
    ...mapMutations("editor", ["DELETE_DYNAMIC_FIELD"]),
    addToDoc(field) {
      let dynamicFieldComponent;
      if (field.content) {
        dynamicFieldComponent = `<dynamic-field format="${field.format}" label="${field.label}" type="${field.type}" formula="${field.formula}" formulatype="${field.formulatype}" formuladate="${field.formuladate}" daystoadd="${field.daystoadd}" content="${field.content}" autodate="${field.autodate}" required="${field.required}"></dynamic-field> `;
      } else {
        dynamicFieldComponent = `<dynamic-field format="${field.format}" label="${field.label}" type="${field.type}" formula="${field.formula}" formulatype="${field.formulatype}" formuladate="${field.formuladate}" daystoadd="${field.daystoadd}" autodate="${field.autodate}" required="${field.required}"></dynamic-field> `;
      }
      this.editor.commands.insertContent(dynamicFieldComponent);
    },
    removeFromDoc(field) {
      (function deleteNodeRecursive(obj, label, type) {
        if (obj.hasOwnProperty("children")) {
          for (let i = 0; i < obj.children.length; i++) {
            deleteNodeRecursive(obj.children[i], label, type);
            if (
              obj.children[i].spec &&
              obj.children[i].spec.node &&
              obj.children[i].spec.node.attrs &&
              obj.children[i].spec.node.attrs.type === type &&
              obj.children[i].spec.node.attrs.label === label
            ) {
              obj.children[i].spec.deleteNode();
              i--; // Suppression -> décrémenter d'1 pour ne pas louper un Node
            }
          }
        }
      })(this.editor.view.docView, field.label, field.type);

      this.DELETE_DYNAMIC_FIELD(field);
    },
    updateInDoc(payload) {
      (function updateNodeRecursive(obj, oldField, newField) {
        if (obj.hasOwnProperty("children")) {
          for (let i = 0; i < obj.children.length; i++) {
            updateNodeRecursive(obj.children[i], oldField, newField);
            if (
              obj.children[i].spec &&
              obj.children[i].spec.node &&
              obj.children[i].spec.node.attrs &&
              obj.children[i].spec.node.attrs.type === oldField.type &&
              obj.children[i].spec.node.attrs.label === oldField.label
            ) {
              obj.children[i].spec.updateAttributes({
                type: newField.type,
                content: newField.content,
                formula: newField.formula,
                required: newField.required,
                label: newField.label,
                format: newField.format,
                formulatype: newField.formulatype,
                formuladate: newField.formuladate,
                daystoadd: newField.daystoadd,
              });
            }
          }
        }
      })(this.editor.view.docView, payload.currentField, payload.editedField);

      (function updateFormulaNodeRecursive(obj, oldField, newField) {
        if (obj.hasOwnProperty("children")) {
          for (let i = 0; i < obj.children.length; i++) {
            updateFormulaNodeRecursive(obj.children[i], oldField, newField);
            if (
              obj.children[i].spec &&
              obj.children[i].spec.node &&
              obj.children[i].spec.node.attrs &&
              obj.children[i].spec.node.attrs.type === "formula" &&
              obj.children[i].spec.node.attrs.formulatype === "number" &&
              obj.children[i].spec.node.attrs.formula.includes(oldField.label)
            ) {
              obj.children[i].spec.updateAttributes({
                formula: obj.children[i].spec.node.attrs.formula.replace(
                  oldField.label,
                  newField.label
                ),
              });
            } else if (
              obj.children[i].spec &&
              obj.children[i].spec.node &&
              obj.children[i].spec.node.attrs &&
              obj.children[i].spec.node.attrs.type === "formula" &&
              obj.children[i].spec.node.attrs.formulatype === "date" &&
              obj.children[i].spec.node.attrs.formuladate.includes(oldField.label)
            ) {
              console.log(obj.children[i].spec.node.attrs.formuladate);
              obj.children[i].spec.updateAttributes({
                formuladate: obj.children[i].spec.node.attrs.formuladate.replace(
                  oldField.label,
                  newField.label
                ),
              });
            }
          }
        }
      })(this.editor.view.docView, payload.currentField, payload.editedField);
    },
    addModuleToDoc(moduleComponent) {
      this.editor.commands.insertContent(moduleComponent + "\n");
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  overflow: hidden;
}

.tableWrapper {
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
</style>
