<template>
  <div>
    <p class="text-lg mb-2">Champs dynamiques</p>
    <b-form
      ref="newFieldForm"
      @submit.prevent="onNewFieldSubmit"
      @reset.prevent="onNewFieldReset"
    >
      <b-form-group label="Type de champ" class="mt-1">
        <b-form-select
          id="type"
          v-model="newDynamicField.type"
          :options="typeOptions"
          name="type-options"
        />
      </b-form-group>
      <b-form-group label="Libellé" class="mt-2">
        <input
          ref="labelInput"
          v-model="newDynamicField.label"
          type="text"
          class="form-control"
          name="label"
          placeholder="Civilité"
          autocomplete="off"
          trim
          required
          @input="updateLabelAutosuggest"
          @focus="updateLabelAutosuggest"
          @keydown="keydownLabelAutosuggest"
        />
        <ul v-if="showLabelAutosuggest" class="autosuggest">
          <li
            v-for="(value, index) in labelAutosuggest"
            :key="`label-autosuggest-${index}`"
            class="autosuggest-item"
            :class="{ selected: index == autosuggestSelectedIndexLabel }"
            @click="selectLabelAutosuggest(value)"
          >
            {{ value }}
          </li>
        </ul>
      </b-form-group>
      <div v-if="newDynamicField.type === 'formula'">
        <b-form-group label="Formule sur" class="mt-2">
          <b-form-select
            id="type"
            v-model="newDynamicField.formulatype"
            :options="formulaTypeOptions"
            name="formula-type-options"
          />
        </b-form-group>
        <b-form-group
          v-if="newDynamicField.formulatype === 'number'"
          v-click-outside="hideAutosuggest"
          label="Formule"
          class="mt-2"
        >
          <textarea
            ref="formulaInput"
            v-model="newDynamicField.formula"
            class="formula-input form-control"
            name="formula"
            placeholder="Formule"
            autocomplete="off"
            @input="updateFormulaAutosuggest"
            @focus="updateFormulaAutosuggest"
            @keydown="keydownFormulaAutosuggest"
          />
          <ul v-if="showFormulaAutosuggest" class="autosuggest">
            <li
              v-for="(value, index) in formulaAutosuggest"
              :key="`formula-autosuggest-${index}`"
              class="autosuggest-item"
              :class="{ selected: index == autosuggestSelectedIndexFormula }"
              @click="selectFormulaAutosuggest(value)"
            >
              {{ value }}
            </li>
          </ul>
        </b-form-group>
        <b-form-group
          v-if="newDynamicField.formulatype === 'date'"
          v-click-outside="hideAutosuggest"
          label="Date"
          class="mt-2"
        >
          <b-form-select
            id="type"
            v-model="newDynamicField.formuladate"
            :options="getFormulaDateOptions"
            name="formula-date-options"
            required
          />
        </b-form-group>
        <b-form-group
          v-if="newDynamicField.formulatype === 'date'"
          v-click-outside="hideAutosuggest"
          label="Nombre de jours à ajouter"
          class="mt-2"
        >
          <b-form-input
            id="days-to-add"
            v-model="newDynamicField.daystoadd"
            type="number"
            name="daysToAdd"
            placeholder="31"
            required
          />
        </b-form-group>
      </div>
      <b-form-group
        v-if="['toFill', 'fixed'].includes(newDynamicField.type)"
        label="Format"
        class="mt-2"
      >
        <b-form-select
          id="format"
          v-model="newDynamicField.format"
          :options="formatOptions"
          name="format-options"
        />
      </b-form-group>
      <div v-if="newDynamicField.type === 'fixed'">
        <b-form-group label="Valeur" class="mt-2">
          <b-form-input
            id="valeur"
            v-model="newDynamicField.content"
            :type="getFixedValueInputType"
            :disabled="newDynamicField.autodate"
            name="content"
            placeholder="Valeur"
          />
        </b-form-group>
        <p>Si laissée vide, la valeur sera automatiquement récupérée depuis l'annuaire.</p>
        <b-form-group class="mt-2" v-if="newDynamicField.format === 'date'">
          <b-form-checkbox
            v-model="newDynamicField.autodate"
            @change="updateNewDynamicFieldContent"
            switch
          >
            Automatiquement afficher la date du jour de la signature
          </b-form-checkbox>
        </b-form-group>
      </div>
      <div v-if="['toFill', 'fixed'].includes(newDynamicField.type)">
        <b-form-group class="mt-2">
          <b-form-checkbox v-model="newDynamicField.required" switch>
            Champ requis
          </b-form-checkbox>
        </b-form-group>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import _ from "lodash";
import axios from "@axios";
import dayjs from "dayjs";

export default {
  props: {
    dynamicField: {
      required: false,
    },
  },
  data() {
    return {
      newDynamicField: {
        type: "toFill",
        label: "",
        format: "text",
        formula: "",
        formulatype: "number",
        formuladate: "",
        daystoadd: 0,
        content: "",
        autodate: false,
        required: true,
      },

      formulaAutosuggest: [],
      showFormulaAutosuggest: false,
      autosuggestSelectedIndexFormula: 0,

      labelAutosuggest: [],
      showLabelAutosuggest: false,
      autosuggestSelectedIndexLabel: 0,

      editedDynamicField: {},
      formatOptions: [
        { text: "Texte", value: "text" },
        { text: "Nombre", value: "number" },
        { text: "Date", value: "date" },
      ],
      typeOptions: [
        { text: "À renseigner", value: "toFill" },
        { text: "Fixe", value: "fixed" },
        { text: "Formule", value: "formula" },
      ],
      formulaTypeOptions: [
        { text: "Nombre", value: "number" },
        { text: "Date", value: "date" },
      ],
    };
  },
  computed: {
    ...mapState("editor", ["dynamicFields"]),
    getFixedValueInputType() {
      return this.newDynamicField.format === "date" ? "date" : "text";
    },
    getFormulaDateOptions() {
      const currentDates = this.dynamicFields.filter(field => field.format === "date");
      let formulaDateOptions = [];
      currentDates.forEach((date) => {
        formulaDateOptions.push({text: date.label, value: date.label});
      })

      return formulaDateOptions;
    }
  },
  mounted() {
    if (this.dynamicField) {
      this.newDynamicField = _.cloneDeep(this.dynamicField);
    }
  },
  methods: {
    ...mapMutations("editor", ["UPDATE_DYNAMIC_FIELD", "ADD_DYNAMIC_FIELD"]),
    submitField() {
      this.$refs.newFieldForm.requestSubmit();
    },
    updateNewDynamicFieldContent(newValue) {
      this.newDynamicField.content = newValue ? "Date du jour" : "";
    },
    hideAutosuggest() {
      this.showFormulaAutosuggest = false;
      this.showLabelAutosuggest = false;
    },
    updateLabelAutosuggest: _.debounce(function (e) {
      const value = e.target.value;
      this.labelAutosuggest = [];
      axios
        .get("/labels", { params: { label_contains: value } })
        .then(({ data }) => {
          this.labelAutosuggest = data.results
            .map((e) => e.label)
            .filter((e) => e !== value);
          this.showLabelAutosuggest = !!this.labelAutosuggest.length;
        })
        .catch(() => {
          this.hideAutosuggest();
        });
    }, 300),
    selectLabelAutosuggest(value) {
      this.newDynamicField.label = value;
      this.autosuggestSelectedIndexLabel = 0;
      this.$refs.labelInput.focus();
      this.hideAutosuggest();
    },
    keydownLabelAutosuggest(e) {
      if (e.key === "ArrowDown") {
        this.autosuggestSelectedIndexLabel++;
        if (
          this.autosuggestSelectedIndexLabel >= this.labelAutosuggest.length
        ) {
          this.autosuggestSelectedIndexLabel = 0;
        }
        e.preventDefault();
      } else if (e.key === "ArrowUp") {
        this.autosuggestSelectedIndexLabel--;
        if (this.autosuggestSelectedIndexLabel < 0) {
          this.autosuggestSelectedIndexLabel = this.labelAutosuggest.length - 1;
        }
        e.preventDefault();
      } else if (e.key === "Enter") {
        this.selectLabelAutosuggest(
          this.labelAutosuggest[this.autosuggestSelectedIndexLabel]
        );
        e.preventDefault();
      }
    },
    updateFormulaAutosuggest(e) {
      const value = e.target.value;
      const startIndex = value.lastIndexOf("[", e.target.selectionStart); // Replace with last alphanum start (e.g. *[*Prix)
      const query = value.substring(startIndex, e.target.selectionStart);

      this.formulaAutosuggest = [];
      if (!query.length) return;

      for (let i = 0; i < this.dynamicFields.length; i++) {
        if (
          this.dynamicFields[i].label
            .toLowerCase()
            .startsWith(query.substring(1).toLowerCase())
        ) {
          this.formulaAutosuggest.push(this.dynamicFields[i].label);
        }
      }

      this.showFormulaAutosuggest = !!this.formulaAutosuggest.length;
    },
    selectFormulaAutosuggest(value) {
      const currentFormula = this.newDynamicField.formula;
      const currentPosition = this.$refs.formulaInput.selectionStart;
      const startPosition =
        currentFormula.lastIndexOf("[", currentPosition) + 1;

      this.newDynamicField.formula =
        currentFormula.substring(0, startPosition) + value;
      this.newDynamicField.formula +=
        currentFormula.substring(currentPosition).charAt(0) !== "]" ? "]" : "";
      this.newDynamicField.formula += currentFormula.substring(currentPosition);

      this.autosuggestSelectedIndexFormula = 0;
      this.$refs.formulaInput.focus();
      this.hideAutosuggest();
    },
    keydownFormulaAutosuggest(e) {
      if (e.key === "ArrowDown") {
        this.autosuggestSelectedIndexFormula++;
        if (
          this.autosuggestSelectedIndexFormula >= this.formulaAutosuggest.length
        ) {
          this.autosuggestSelectedIndexFormula = 0;
        }
        e.preventDefault();
      } else if (e.key === "ArrowUp") {
        this.autosuggestSelectedIndexFormula--;
        if (this.autosuggestSelectedIndexFormula < 0) {
          this.autosuggestSelectedIndexFormula =
            this.formulaAutosuggest.length - 1;
        }
        e.preventDefault();
      } else if (e.key === "Enter") {
        this.selectFormulaAutosuggest(
          this.formulaAutosuggest[this.autosuggestSelectedIndexFormula]
        );
        e.preventDefault();
      }
    },
    onNewFieldSubmit() {
      let completedField = _.cloneDeep(this.newDynamicField);

      if (
        !this.dynamicField ||
        this.dynamicField.label !== completedField.label
      ) {
        if (this.dynamicFields.find((e) => e.label === completedField.label)) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Un champ dynamique possède déjà ce libellé",
                icon: "XIcon",
                variant: "danger",
              },
            },
            {
              timeout: 3500,
            }
          );
          return;
        }
      }
      if (this.dynamicField) {
        const payload = {
          currentField: this.dynamicField,
          editedField: completedField,
        };
        console.log(payload);
        this.UPDATE_DYNAMIC_FIELD(payload);
        this.$emit("update-field", payload);
      } else {
        this.ADD_DYNAMIC_FIELD(completedField);
        this.$emit("add-field", completedField);
      }
      this.$refs.newFieldForm.reset();
    },
    onNewFieldReset() {
      this.newDynamicField.type = "toFill";
      this.newDynamicField.label = "";
      this.newDynamicField.formula = "";
      this.newDynamicField.formulatype = "number";
      this.newDynamicField.formuladate = "";
      this.newDynamicField.daystoadd = 0;
      this.newDynamicField.format = "text";
      this.newDynamicField.content = "";
      this.newDynamicField.autodate = false;
      this.newDynamicField.required = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.formula-input {
  height: auto;
  width: 100%;
}

.autosuggest {
  max-height: 200px;
  overflow-y: scroll;
  padding: 0;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.5rem;

  .autosuggest-item {
    padding: 0.438rem 1rem;
    cursor: pointer;
    list-style-type: none;

    &.selected {
      background: #e1e1e1;
    }
  }
}
</style>