<template>
  <div
    class="
      py-1
      px-2
      top-bar
      d-flex
      flex-row
      justify-content-between
      align-items-center
    "
  >
    <div class="d-flex flex-row align-items-center">
      <button class="btn btn-light mr-2" @click.prevent="quit()">
        <i class="ri-arrow-left-line mr-50" />
        <span class="mr-50">Quitter</span>
      </button>
      <div class="v-divider" />
      <b-form-input
        v-if="id"
        id="title-input"
        v-autowidth="{ maxWidth: '488px', minWidth: 'none', comfortZone: 0 }"
        class="title-input"
        type="text"
        :value="title"
        @input="SET_TITLE($event)"
        :disabled="lock"
      />
      <tag-file-type :type="getType" />
    </div>
    <b-button v-if="saveLoading" variant="primary" disabled>
      <b-spinner class="mr-50" small />
      Chargement...
    </b-button>
    <b-button v-else-if="lock" variant="primary" disabled>
      <i class="ri-save-line mr-50" />Document en lecture seule
    </b-button>
    <b-button v-else variant="primary" @click="save">
      <i class="ri-save-line mr-50" />Sauvegarder
    </b-button>
  </div>
</template>

<script>
import axios from "@axios";
import useJwt from "@/auth/jwt/useJwt";

import { mapMutations, mapState, mapActions } from "vuex";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import TagFileType from "@/components/TagFileType";

export default {
  components: {
    TagFileType,
  },
  props: {
    editor: {
      required: true,
    },
  },
  data() {
    return {
      editorJson: null,
      saveLoading: false,
    };
  },
  computed: {
    ...mapState("editor", ["id", "dynamicFields", "title", "copy", "lock"]),
    getType() {
      const editorJson = this.editor.getJSON();
      if (
        editorJson.content.some(
          (el) => el.type === "checkboxes" || el.type === "questionnaire"
        ) ||
        this.dynamicFields.some((field) => field.type === "toFill")
      ) {
        return "TO_FILL";
      }
      return "FILLED";
    },
  },
  methods: {
    ...mapMutations("editor", ["SET_TITLE"]),
    ...mapActions("editor", ["updateDocument"]),
    /**
     * Save the content of the editor
     */
    save() {
      this.saveLoading = true;
      this.editorJson = this.editor.getJSON();

      axios
        .put(`/document-templates/${this.$route.params.id}`, {
          title: this.title,
          content: this.editorJson,
          type: this.getType,
          dynamicFields: this.dynamicFields,
          vitalsignRefreshToken: useJwt.getRefreshToken(),
        })
        .then((res) => {
          console.log(res.data);
          this.updateDocument(res.data);
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Document sauvegardé",
                icon: "CheckIcon",
                variant: "success",
              },
            },
            {
              timeout: 1500,
            }
          );
          this.saveLoading = false;
          // history.replaceState({}, null, this.$route.path + '/' + res.data.id);
        });
    },
    /**
     * Quit the editor
     */
    quit() {
      if (this.hasUnsavedChanged()) {
        this.$swal
          .fire({
            title: "Souhaitez-vous quitter l'édition ?",
            text: "Les modifications non sauvegardées seront perdues.",
            icon: "warning",
            iconColor: "#F14A3F",
            confirmButtonText: "Quitter",
            showCancelButton: true,
            buttonsStyling: false,
            width: "512px",
            customClass: {
              confirmButton: "btn btn-danger mr-50 my-1",
              cancelButton: "btn btn-outline-primary my-1",
            },
          })
          .then(({ isConfirmed }) => {
            if (isConfirmed) {
              this.$router.push({ name: "manager" });
            }
          });
      } else {
        this.$router.push({ name: "manager" });
      }
    },
    hasUnsavedChanged() {
      const editorContent = this.editor.getJSON();
      if (
        this.copy.title === this.title &&
        _.isEqual(this.copy.dynamicFields, this.dynamicFields) &&
        _.isEqual(this.copy.content, editorContent)
      ) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped lang="scss">
.top-bar {
  border-bottom: 0.7px solid $gray-400;
}

.title-input {
  margin: 0 8px;
  padding: 4px 12px;
  font-size: $font-size-lg;
  margin: 0 8px;
  background: transparent;
  border: 1px solid transparent;

  &:hover,
  &:active {
    border: 1px solid transparent;
    background-color: $gray-300;
  }

  &:focus {
    color: $black-400;
    border: 1px solid transparent;
    background-color: $gray-300;
  }
}
</style>