<template>
  <div
    class="
      item-wrapper
      d-flex
      flex-row
      align-items-center
      justify-content-between
      p-2
    "
  >
    <div class="d-flex flex-column">
      <div class="d-flex flex-row align-items-center">
        <p class="mb-0 mr-50 title">
          {{ field.label }}
        </p>
        <tag :type="field.type" />
      </div>
      <p
        v-if="field.type === 'fixed'"
        class="fixed-field-value"
      >
        Valeur: {{ field.content || "issue de l'annuaire" }}
      </p>
    </div>
    <div class="d-flex flex-row align-items-center">
      <div
        class="icon-btn"
        @click="addToDoc"
      >
        <i class="ri-add-line ri-lg" />
      </div>
      <div
        class="icon-btn"
        @click="editField"
      >
        <i class="ri-edit-box-line ri-lg" />
      </div>
      <div
        class="icon-btn"
        @click="deleteField"
      >
        <i class="ri-delete-bin-5-line ri-lg" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

import Tag from "@/components/Tag";

export default {
  components: {
    Tag,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapMutations("editor", ["deleteField"]),
    addToDoc() {
      this.$emit('add-to-doc', this.field);
    },
    deleteField() {
      this.$emit('remove-from-doc', this.field);
    },
    editField() {
      this.$emit('edit-field', this.field);
    }
  },
};
</script>

<style lang="scss" scoped>
.item-wrapper {
  &:hover {
    background-color: $gray-300;
  }

  .title {
    font-size: $font-size-base;
  }

  .fixed-field-value {
    margin: 0;
    font-size: $font-size-sm;
    color: $black-300;  
  }
}
</style>