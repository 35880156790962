<template>
  <div class="questionnaire-form-wrapper">
    <div class="d-flex flex-row align-items-center px-3 text-lg mb-2">
      <i class="ri-survey-line ri-md mr-1 module-icon" />
      <p class="mb-0">
        Questionnaire
      </p>
    </div>
    <form
      ref="questionnaireForm"
      @submit.prevent="processForm"
    >
      <div class="px-3 mb-2">
        <b-form-group label="Titre">
          <b-form-input
            v-model="newQuestionnaireModule.title"
            type="text"
            placeholder="Titre"
            required
            trim
          />
        </b-form-group>
        <b-form-checkbox
          v-model="newQuestionnaireModule.uniqueAnswers"
          switch
          class="pt-1"
        >
          Réponses à choix unique
        </b-form-checkbox>
        <b-form-checkbox
          v-model="newQuestionnaireModule.isMarked"
          switch
          class="pt-1"
        >
          Questionnaire à points
        </b-form-checkbox>
      </div>
      <div
        class="accordion"
        role="tablist"
      >
        <div
          v-for="(question, idx) in newQuestionnaireModule.questions"
          :key="idx"
          class="question-wrapper"
        >
          <div
            v-b-toggle="'accordion-' + idx"
            role="tab"
            class="
              question-header
              d-flex
              flex-row
              align-items-center
              justify-content-between
              px-2
              pointer
            "
          >
            <p class="py-2 mb-0">
              Question {{ idx + 1 }}
            </p>
            <div
              class="icon-btn"
              @click.stop="deleteQuestion(idx)"
            >
              <i class="ri-delete-bin-5-line ri-lg" />
            </div>
          </div>

          <b-collapse
            :id="`accordion-${idx}`"
            accordion="questionnaire-accordion"
            role="tabpanel"
            class="px-2"
            :visible="isVisible(idx)"
          >
            <div class="pb-2">
              <b-form-group label="Intitulé">
                <b-form-input
                  v-model="question.label"
                  type="text"
                  placeholder="Intitulé"
                  required
                  trim
                />
              </b-form-group>
              <div
                v-for="(answer, answerIdx) in question.answers"
                :key="answerIdx"
                :class="{ 'question-spacing': newQuestionnaireModule.isMarked }"
              >
                <b-form-group :label="`Réponse n°${answerIdx + 1}`">
                  <div
                    class="
                      d-flex
                      flex-row
                      align-items-center
                      justify-content-between
                    "
                  >
                    <b-form-input
                      v-model="answer.label"
                      type="text"
                      placeholder="Réponse"
                      required
                      trim
                    />
                    <div
                      class="icon-btn ml-50"
                      @click="removeAnswer(idx, answerIdx)"
                    >
                      <i class="ri-delete-bin-5-line ri-lg" />
                    </div>
                  </div>
                </b-form-group>
                <b-form-group
                  v-if="newQuestionnaireModule.isMarked"
                  :label="`Points réponse n°${answerIdx + 1}`"
                >
                  <b-form-input
                    v-model="answer.marks"
                    type="number"
                    placeholder="Points"
                    class="number-input"
                    required
                    trim
                  />
                </b-form-group>
              </div>
              <b-button
                variant="light"
                size="sm"
                @click="addAnswer(idx)"
              >
                Ajouter une réponse
              </b-button>
            </div>
          </b-collapse>
        </div>
      </div>
      <b-button
        block
        variant="light"
        size="sm"
        class="py-1 sharp-btn"
        @click="addQuestion"
      >
        <i class="ri-add-line align-middle ri-xl mr-50" /> Nouvelle question
      </b-button>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newQuestionnaireModule: {
        title: "",
        isMarked: false,
        uniqueAnswers: true,
        questions: [
          {
            label: "",
            answers: [
              { label: "", marks: 0 },
              { label: "", marks: 0 },
            ],
          },
        ],
      },
    };
  },
  methods: {
    addQuestion() {
      this.newQuestionnaireModule.questions.push({
        label: "",
        answers: [
          { label: "", marks: 0, selected: false },
          { label: "", marks: 0, selected: false },
        ],
      });
    },
    deleteQuestion(idx) {
      this.newQuestionnaireModule.questions.splice(idx, 1);
    },
    addAnswer(questionIdx) {
      this.newQuestionnaireModule.questions[questionIdx].answers.push({
        label: "",
        marks: 0,
        selected: false,
      });
    },
    removeAnswer(questionIdx, answerIdx) {
      this.newQuestionnaireModule.questions[questionIdx].answers.splice(
        answerIdx,
        1
      );
    },
    submitModule() {
      this.$refs.questionnaireForm.requestSubmit();
    },
    processForm() {
      let questionnaireComponent = `<questionnaire title="${
        this.newQuestionnaireModule.title
      }" isMarked="${
        this.newQuestionnaireModule.isMarked
      }" uniqueAnswers="${
        this.newQuestionnaireModule.uniqueAnswers
      }" questions='${JSON.stringify(
        this.newQuestionnaireModule.questions
      )}'></questionnaire>`;
      console.log(questionnaireComponent);
      this.$emit("add-module-to-doc", questionnaireComponent);
    },
    isVisible(idx) {
      return idx === 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.question-wrapper {
  border-top: 0.7px solid $gray-400;

  &:last-child {
    border-bottom: 0.7px solid $gray-400;
  }
}

.sharp-btn {
  border-radius: 0;
}

.question-spacing {
  padding: 12px 0;
}

.number-input {
  width: 80px;
}
</style>