<template>
  <div
    v-if="editor"
    class="bubble-menu"
    :editor="editor"
  >
    Largeur
    <b-form-input
      type="number"
      min="1"
      max="100"
      step="1"
      :value="editor.getAttributes('custom-image').width"
      @input="editor.chain().setWidth($event).run()"
    />%
    <span style="color: #aaa">|</span>

    <!-- Align left -->
    <button
      class="bubble-btn"
      :class="{ active: editor.isActive('custom-image', { alignment: 'left' }) }"
      @click="editor.chain().focus().setAlignment('left').run()"
    >
      <i class="ri-align-left" />
    </button>
    <!-- Align center -->
    <button
      class="bubble-btn"
      :class="{ active: editor.isActive('custom-image', { alignment: 'center' }) }"
      @click="editor.chain().focus().setAlignment('center').run()"
    >
      <i class="ri-align-center" />
    </button>
    <!-- Align right -->
    <button
      class="bubble-btn"
      :class="{ active: editor.isActive('custom-image', { alignment: 'right' }) }"
      @click="editor.chain().focus().setAlignment('right').run()"
    >
      <i class="ri-align-right" />
    </button>

    <span style="color: #aaa">|</span>
    <button
      class="bubble-btn"
      :class="{
        active: editor.isActive('custom-image', {
          float: 'left',
        }),
      }"
      @click="editor.chain().focus().setFloat('left').run()"
    >
      <i class="ri-indent-decrease" />
    </button>
    <button
      class="bubble-btn"
      :class="{
        active: editor.isActive('custom-image', {
          float: 'right',
        }),
      }"
      @click="editor.chain().focus().setFloat('right').run()"
    >
      <i class="ri-indent-increase" />
    </button>
    <div class="h-divider"></div>
    <button @click="addImage">Remplacer l'image</button>
  </div>
</template>


<script>
export default {
  props: {
    editor: {
      required: true,
    },
  },
  methods: {
    addImage() {
      const url = window.prompt(
        "Image URL",
        // Using picsum.photo/id/... and NOT
        // picsum.photos/w/h or the image will
        // change when we change the size and
        // tiptap redraws
        `https://picsum.photos/id/${
          Math.floor(Math.random() * 200) + 1
        }/200/300`
      );
      if (url) {
        this.editor.chain().focus().setImage({ src: url }).run();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
input[type=number] {
  display: inline;
  width: 40px;
  height: 26px;
  padding: 0 5px;
}
</style>