<template>
  <div
    class="d-flex align-items-center"
    :class="tagObject"
  >
    <span>{{ getLabel }}</span>
  </div>
</template>

<script>
export default {
  props: {
    //dynamic field type: 'toFill' or 'fixed' or 'formula'
    type: {
      required: true,
      type: String,
    },
  },
  computed: {
    tagObject() {
      return {
        toFill: this.type === "toFill",
        fixed: this.type === "fixed",
        formula: this.type === "formula",
      };
    },
    getLabel() {
      if (this.type === "toFill") {
        return "À renseigner";
      } else if (this.type === "fixed") {
        return "Fixe";
      } else if (this.type === "formula") {
        return "Formule";
      } else {
        return "Inconnu";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  padding: 4px 8px;
  border-radius: $border-radius;
  font-family: $font-family-sans-serif;
  font-size: $font-size-sm;
  letter-spacing: -0.02rem;
}

.toFill {
  background-color: $blue-200;
  color: $vitalsign-blue;
}

.fixed {
  background-color: $red-200;
  color: $red;
}

.formula {
  background-color: $green-200;
  color: $green;
}
</style>