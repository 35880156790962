<template>
  <div class="checkbox-form-wrapper">
    <div class="d-flex flex-row align-items-center px-3 text-lg mb-2">
      <i class="ri-checkbox-multiple-line ri-md mr-1 module-icon" />
      <p class="mb-0">Cases à cocher</p>
    </div>
    <div class="px-3">
      <form ref="checkboxesForm" @submit.prevent="processForm">
        <b-form-group label="Libellé">
          <b-form-input
            v-model="newCheckboxesModule.label"
            type="text"
            placeholder="Libellé"
            required
            trim
          />
        </b-form-group>
        <b-form-checkbox
          v-model="newCheckboxesModule.uniqueAnswers"
          switch
          class="pb-50"
        >
          Choix unique
        </b-form-checkbox>
        <b-form-group class="mt-2">
          <b-form-checkbox v-model="newCheckboxesModule.required" switch>
            Réponse requise
          </b-form-checkbox>
        </b-form-group>
        <b-form-select
          v-model="newCheckboxesModule.selectedColor"
          :options="customColors"
          class="mb-2"
        ></b-form-select>
        <div v-for="(box, idx) in newCheckboxesModule.boxes" :key="idx">
          <b-form-group :label="`Case n°${idx + 1}`">
            <div
              class="d-flex flex-row align-items-center justify-content-between"
            >
              <b-form-input
                v-model="box.label"
                type="text"
                placeholder="Choix"
                required
                trim
              />
              <div class="icon-btn ml-50" @click="removeCheckbox(idx)">
                <i class="ri-delete-bin-5-line ri-lg" />
              </div>
            </div>
          </b-form-group>
        </div>
        <b-button variant="light" size="sm" class="py-1" @click="addCheckbox">
          <i class="ri-add-line align-middle ri-xl mr-50" /> Ajouter une case
        </b-button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  inheritAttrs: false,
  emits: ["addModuleToDoc"],
  data() {
    return {
      newCheckboxesModule: {
        label: "",
        uniqueAnswers: true,
        required: false,
        selectedColor: "default",
        boxes: [
          { label: "", selected: false },
          { label: "", selected: false },
        ],
      },
      customColors: [
        { value: "default", text: "Couleur par défaut" },
        { value: "yellow", text: "Jaune" },
        { value: "black", text: "Noir" },
        { value: "orange", text: "Orange" },
        { value: "pink", text: "Rose" },
        { value: "red", text: "Rouge" },
        { value: "green", text: "Vert" },
        { value: "purple", text: "Violet" },
      ],
    };
  },
  methods: {
    ...mapMutations("editor", ["ADD_MODULE"]),
    addCheckbox() {
      this.newCheckboxesModule.boxes.push({ label: "", selected: false });
    },
    removeCheckbox(idx) {
      this.newCheckboxesModule.boxes.splice(idx, 1);
    },
    submitModule() {
      this.$refs.checkboxesForm.requestSubmit();
    },
    processForm() {
      const checkboxesComponent = `<checkboxes label="${encodeURI(
        this.newCheckboxesModule.label
      )}" uniqueAnswers="${encodeURI(
        this.newCheckboxesModule.uniqueAnswers
      )}" color="${encodeURI(this.newCheckboxesModule.selectedColor)}"
       checkboxes="${encodeURI(
         JSON.stringify(this.newCheckboxesModule.boxes)
       )}" required="${this.newCheckboxesModule.required}"></checkboxes>`;

      this.$emit("add-module-to-doc", checkboxesComponent);
    },
  },
};
</script>

<style lang="scss" scoped>
.module-icon {
  margin-top: 1px;
}
</style>