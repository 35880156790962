<template>
  <div class="sidebar d-flex flex-column justify-content-between" :class="{ disabled: lock }">
    <div
      class="tabs d-flex flex-row align-items-stretch justify-content-between"
    >
      <div
        class="field-tab w-100 text-center py-2"
        :class="{ 'active-tab': currentTab === 'fields' }"
        @click="setTab('fields')"
      >
        <p class="mb-0">Champs dynamiques</p>
      </div>
      <div
        class="module-tab w-100 text-center py-2"
        :class="{ 'active-tab': currentTab === 'modules' }"
        @click="setTab('modules')"
      >
        <p class="mb-0">Modules</p>
      </div>
    </div>
    <!-- Main section -->
    <div class="main pt-3 d-flex flex-column flex-grow-1">
      <!-- Fields tab -->
      <div v-if="currentTab === 'fields'">
        <!-- List state -->
        <div
          v-if="!isAddingField && !isEditingField"
          class="d-flex flex-column"
        >
          <!-- Title & search bar -->
          <div class="px-3">
            <p class="text-lg mb-2">Champs dynamiques</p>
            <search-bar ref="searchbar" class="mb-3" @input="searchInput" />
          </div>
          <!-- Dynamic fields list -->
          <p v-if="!dynamicFields.length" class="mx-auto">
            Aucun champ dynamique.
          </p>
          <template v-for="(field, idx) in dynamicFields">
            <div
              v-if="!hiddenDynamicFieldsMatrix.includes(idx)"
              :key="idx"
              class="list-item"
            >
              <dynamic-field-list-item
                :field="field"
                class="dynamic-field"
                @add-to-doc="addToDoc"
                @remove-from-doc="removeFromDoc"
                @edit-field="editField"
              />
            </div>
          </template>
        </div>
        <!-- New field state -->
        <div v-else-if="isAddingField" class="px-3">
          <dynamic-field-form ref="addFieldForm" @add-field="addToDoc" />
        </div>
        <!-- Field edition state -->
        <div v-else-if="isEditingField" class="px-3">
          <dynamic-field-form
            ref="editFieldForm"
            :dynamic-field="editedField"
            @update-field="updateField"
          />
        </div>
      </div>
      <!-- Modules tab -->
      <div v-if="currentTab === 'modules'">
        <!-- List of all modules -->
        <div v-if="!isAddingModule" class="d-flex flex-column">
          <p class="px-3 text-lg mb-2">Choisir un module</p>
          <div
            v-for="(uniqueModule, idx) in modulesList"
            :key="idx"
            class="list-item"
            @click="selectModule(uniqueModule.type)"
          >
            <module-list-item :uniqueModule="uniqueModule" />
          </div>
        </div>
        <!-- Selected module form -->
        <div v-if="isAddingModule">
          <component
            :is="selectedModuleComponent"
            ref="moduleComponent"
            @add-module-to-doc="addModuleToDoc"
          />
        </div>
      </div>
    </div>
    <!-- Actions section -->
    <div
      v-if="currentTab === 'fields'"
      class="
        action
        py-2
        px-3
        d-flex
        flex-row
        align-items-center
        justify-content-between
      "
    >
      <b-button
        v-if="!isAddingField && !isEditingField"
        block
        variant="outline-primary"
        class="justify-content-center"
        @click="isAddingField = true"
      >
        <i class="ri-add-fill ri-lg mr-50" />Ajouter un champ
      </b-button>
      <div
        v-else-if="isAddingField"
        class="d-flex flex-row w-100 justify-content-between"
      >
        <b-button
          variant="outline-primary"
          class="flex-fill mr-50 justify-content-center"
          @click="isAddingField = false"
        >
          Annuler
        </b-button>
        <b-button
          variant="primary"
          class="flex-fill ml-50 pl-1 justify-content-center"
          @click="$refs.addFieldForm.submitField()"
        >
          <i class="ri-check-fill ri-lg mr-50" />Ajouter
        </b-button>
      </div>
      <div
        v-else-if="isEditingField"
        class="d-flex flex-row w-100 justify-content-between"
      >
        <b-button
          variant="outline-primary"
          class="flex-fill mr-50 justify-content-center"
          @click="isEditingField = false"
        >
          Annuler
        </b-button>
        <b-button
          variant="primary"
          class="flex-fill ml-50 pl-1 justify-content-center"
          @click="$refs.editFieldForm.submitField()"
        >
          <i class="ri-check-fill ri-lg mr-50" />Mettre à jour
        </b-button>
      </div>
    </div>
    <div
      v-if="currentTab === 'modules'"
      class="
        action
        py-2
        px-3
        d-flex
        flex-row
        align-items-center
        justify-content-between
      "
    >
      <div
        v-if="isAddingModule"
        class="d-flex flex-row w-100 justify-content-between"
      >
        <b-button
          variant="outline-primary"
          class="flex-fill mr-50 justify-content-center"
          @click="isAddingModule = false"
        >
          Annuler
        </b-button>
        <b-button
          variant="primary"
          class="flex-fill ml-50 pl-1 justify-content-center"
          @click="addModule"
        >
          <i class="ri-check-fill ri-lg mr-50" />Ajouter
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

import SearchBar from "@/components/SearchBar";
import DynamicFieldListItem from "@/components/editor/DynamicFieldListItem";
import ModuleListItem from "@/components/editor/ModuleListItem";
import CheckboxesForm from "@/components/editor/CheckboxesForm";
import QuestionnaireForm from "@/components/editor/QuestionnaireForm";
import SpoilerForm from "@/components/editor/SpoilerForm";
import DynamicFieldForm from "@/components/editor/DynamicFieldForm";

export default {
  components: {
    SearchBar,
    DynamicFieldListItem,
    ModuleListItem,
    CheckboxesForm,
    QuestionnaireForm,
    SpoilerForm,
    DynamicFieldForm,
  },
  data() {
    return {
      currentTab: "fields",
      isAddingField: false,
      isEditingField: false,
      isAddingModule: false,
      selectedModuleComponent: "",
      hiddenDynamicFieldsMatrix: [],
      modulesList: [
        {
          type: "checkboxes",
          label: "Cases à cocher",
          icon: "ri-checkbox-multiple-line",
        },
        {
          type: "questionnaire",
          label: "Questionnaire",
          icon: "ri-survey-line",
        },
        // {
        //   type: "spoiler",
        //   label: "Contenu conditionnel",
        //   icon: "ri-node-tree",
        // },
      ],
      editedField: {},
    };
  },
  computed: {
    ...mapState("editor", ["dynamicFields", "modules", "lock"]),
  },
  methods: {
    ...mapMutations("editor", ["ADD_DYNAMIC_FIELD"]),
    searchInput(e) {
      this.hiddenDynamicFieldsMatrix = [];
      if (!e.length) return;

      for (let i = 0; i < this.dynamicFields.length; i++) {
        if (
          !this.dynamicFields[i].label.toLowerCase().startsWith(e.toLowerCase())
        ) {
          this.hiddenDynamicFieldsMatrix.push(i);
        }
      }
    },

    addToDoc(field) {
      this.$emit("add-to-doc", field);
      if (this.isAddingField) this.isAddingField = false;
    },
    setTab(newTab) {
      this.currentTab = newTab;
    },
    removeFromDoc(field) {
      this.$swal
        .fire({
          title: "Souhaitez-vous supprimer ce champ ?",
          text: "Toutes les occurrences de ce champ dynamique seront supprimées du document. Les formules comportant ce champ devront être modifiées.",
          icon: "warning",
          iconColor: "#F14A3F",
          confirmButtonText: "Supprimer",
          showCancelButton: true,
          buttonsStyling: false,
          width: "512px",
          customClass: {
            confirmButton: "btn btn-danger mr-50 my-1",
            cancelButton: "btn btn-outline-primary my-1",
          },
        })
        .then(({ isConfirmed }) => {
          if (isConfirmed) {
            this.hiddenDynamicFieldsMatrix = [];
            this.$refs.searchbar.resetQuery();
            this.$emit("remove-from-doc", field);
          }
        });
    },
    addModule() {
      this.$refs.moduleComponent.submitModule();
    },
    addModuleToDoc(moduleComponent) {
      this.$emit("add-module-to-doc", moduleComponent);
      this.isAddingModule = false;
    },
    selectModule(selectedModule) {
      if (selectedModule === "checkboxes") {
        this.selectedModuleComponent = "CheckboxesForm";
      } else if (selectedModule === "questionnaire") {
        this.selectedModuleComponent = "QuestionnaireForm";
      } else if (selectedModule === "spoiler") {
        this.selectedModuleComponent = "SpoilerForm";
      }

      this.isAddingModule = true;
    },
    editField(field) {
      this.editedField = _.cloneDeep(field);
      console.log(this.editedField);
      this.isEditingField = true;
    },
    updateField(payload) {
      this.isEditingField = false;
      this.$emit("update-field", payload);
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  flex: 0 0 512px;
  border-left: 0.7px solid $gray-400;

  @media screen and (max-width: map-get($grid-breakpoints , "xxxl" )) {
    flex: 0 0 472px;
  }

  @media screen and (max-width: map-get($grid-breakpoints , "xxl" )) {
    flex: 0 0 432px;
  }

  @media screen and (max-width: map-get($grid-breakpoints , "xl" )) {
    flex: 0 0 340px;
  }

  @media screen and (max-width: map-get($grid-breakpoints , "lg" )) {
    flex: 0 0 300px;
  }

  .tabs {
    cursor: pointer;
    border-bottom: 0.7px solid $gray-400;

    .field-tab {
      border-right: 0.7px solid $gray-400;
    }

    .active-tab {
      color: $vitalsign-blue;
      border-bottom: 1px solid $blue-300;
    }
  }

  .main {
    height: 0;
    overflow-y: auto;
  }

  .action {
    border-top: 0.7px solid $gray-400;
  }

  .libelle-input {
    margin-top: 28px;
  }

  .list-item {
    border-top: 0.7px solid $gray-400;

    &:last-child {
      border-bottom: 0.7px solid $gray-400;
    }
  }
}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.4;
  
  * {
    pointer-events: none;
  }
}
</style>