
<template>
  <div>
    <div id="bubble-menu-table" v-if="template !== 'auth-certificate' && template !== 'collectif-auth-certificate'">
      <bubble-menu-table :editor="editor" />
    </div>
    <div id="bubble-menu-image">
      <bubble-menu-image :editor="editor" />
    </div>
  </div>
</template>


<script>
import BubbleMenuTable from "./BubbleMenuTable";
import BubbleMenuImage from "./BubbleMenuImage";
import { mapState } from 'vuex';

export default {
  components: {
    BubbleMenuTable,
    BubbleMenuImage,
  },
  props: {
    editor: {
      required: true,
    },
  },
  computed: {
    ...mapState("editor", ["template"]),
  }
};
</script>

<style lang="scss">
.bubble-menu {
  // display: flex;
  display: block;
  height: auto;

  // background-color: #0d0d0d;
  padding: 0.2rem;
  border-radius: 0.5rem;

  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%),
    0 1px 18px 0 rgb(0 0 0 / 12%);
  padding: 6px 12px;
  transition: all 0.3s ease-in-out;
  z-index: 50;

  &.is-active {
    opacity: 1;
  }

  button {
    border: none;
    background: none;
    color: #000;
    font-size: 1rem;
    // padding: 0 8px;

    &:hover,
    &.is-active {
      opacity: 1;
    }
    &.is-active {
      text-decoration: underline;
    }
  }

  .bubble-btn {
    margin: 0 1px;
    padding: 4px 8px;
    background-color: transparent;
    border: none;
    border-radius: $border-radius;
    color: $black-400;

    &:hover {
      color: $black-500;
      background-color: $gray-300;
    }

    &.active {
      color: $vitalsign-blue;
      background-color: $blue-200;
    }
  }
}
</style>