<template>
  <div
    class="
      item-wrapper
      d-flex
      flex-row
      align-items-center
      justify-content-between
      p-2
    "
  >
    <div class="d-flex flex-row align-items-center">
      <i
        :class="uniqueModule.icon"
        class="ri-xl mr-1 module-icon"
      />
      <p class="mb-0 mr-50 title">
        {{ uniqueModule.label }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: {
    uniqueModule: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapMutations("editor", ["editField", "deleteField"]),
  },
};
</script>

<style lang="scss" scoped>
.item-wrapper {
  cursor: pointer;

  &:hover {
    background-color: $gray-300;
  }

  .title {
    font-size: $font-size-base;
  }

  .fixed-field-value {
    margin: 0;
    font-size: $font-size-sm;
    color: $black-300;
  }

  .module-icon {
    color: $black-300;
  }
}
</style>