var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.editor)?_c('div',{staticClass:"bubble-menu",attrs:{"editor":_vm.editor}},[_vm._v(" Largeur "),_c('b-form-input',{attrs:{"type":"number","min":"1","max":"100","step":"1","value":_vm.editor.getAttributes('custom-image').width},on:{"input":function($event){_vm.editor.chain().setWidth($event).run()}}}),_vm._v("% "),_c('span',{staticStyle:{"color":"#aaa"}},[_vm._v("|")]),_c('button',{staticClass:"bubble-btn",class:{ active: _vm.editor.isActive('custom-image', { alignment: 'left' }) },on:{"click":function($event){_vm.editor.chain().focus().setAlignment('left').run()}}},[_c('i',{staticClass:"ri-align-left"})]),_c('button',{staticClass:"bubble-btn",class:{ active: _vm.editor.isActive('custom-image', { alignment: 'center' }) },on:{"click":function($event){_vm.editor.chain().focus().setAlignment('center').run()}}},[_c('i',{staticClass:"ri-align-center"})]),_c('button',{staticClass:"bubble-btn",class:{ active: _vm.editor.isActive('custom-image', { alignment: 'right' }) },on:{"click":function($event){_vm.editor.chain().focus().setAlignment('right').run()}}},[_c('i',{staticClass:"ri-align-right"})]),_c('span',{staticStyle:{"color":"#aaa"}},[_vm._v("|")]),_c('button',{staticClass:"bubble-btn",class:{
      active: _vm.editor.isActive('custom-image', {
        float: 'left',
      }),
    },on:{"click":function($event){_vm.editor.chain().focus().setFloat('left').run()}}},[_c('i',{staticClass:"ri-indent-decrease"})]),_c('button',{staticClass:"bubble-btn",class:{
      active: _vm.editor.isActive('custom-image', {
        float: 'right',
      }),
    },on:{"click":function($event){_vm.editor.chain().focus().setFloat('right').run()}}},[_c('i',{staticClass:"ri-indent-increase"})]),_c('div',{staticClass:"h-divider"}),_c('button',{on:{"click":_vm.addImage}},[_vm._v("Remplacer l'image")])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }